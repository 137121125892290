:root {
  display: none;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}

#ie-container {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #f9f9f9;
  overflow: auto;

  .ie-header {
    position: relative;
    width: 100%;
    background-color: white;
    box-shadow: 0 0.15rem 0.8rem 0 rgba(74, 74, 74, 0.4);
    z-index: 10;

    .ie-content {
      display: block;
      position: relative;
      width: 100%;
      margin: auto;
      text-align: left;
      padding: 20px;

      @media only screen and (min-width: 1000px) {
        width: 1000px;
      }
    }
  }

  #ie-message {
    color: #4a4a4a;
    background: #fff;
    position: relative;
    z-index: 2;
    margin: auto;
    border-radius: 5px;
    max-width: 550px;
    padding: 25px 30px;
    box-shadow: 0px 0px 2px 1px lightgrey;
    font-size: 16px;
    font-weight: 300;
    margin-top: 50px;

    @media only screen and (max-width: 768px) {
      max-width: 768px;
      margin: 20px 10px 10px;
    }

    h1 {
      font-size: 20px;
      color: #2a498c;
      margin-bottom: 21px;
    }

    p {
      line-height: 35px;
      &#link-text {
        margin-top: 21px;
        font-weight: bold;
        word-break: break-all;
        line-height: 20px;
      }
    }
  }
}
